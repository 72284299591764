.NewPost {
  margin: 20px auto;
}

.NewPost h3 a:hover {
  text-decoration: underline;
}

.NewPost form {
  padding: 15px 0;
}

.NewPost .title {
  margin-top: 0;
  margin-bottom: 0;
}

.NewPost form textarea, .NewPost form select {
  margin-bottom: 20px;
}

.NewPost .preview-pane {
  margin-top: 15px;
}

.NewPost .preview-pane a {
  text-decoration: underline;
  background: #eee;
}

.NewPost pre.lowlight {
  padding: 0;
  margin: 0;
  background: initial;
  border: none;
  font-size: inherit;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
  .NewPost .LoaderButton {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding-top: 6px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1020;
    text-indent: -99999px;
    line-height: 0;
  }

  .NewPost .LoaderButton .spinning {
    display: none;
  }

  .NewPost .LoaderButton::after {
    text-indent: 0;
    display: block;
    line-height: initial;
    content: "GO";
    font-weight: bold;
  }
}