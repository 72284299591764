body {
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #222;
  transition: 0.5s all;
}

a:hover {
  color: #000;
  text-decoration: none;
}

p, ul li {
  color: #000;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 1.5rem;
}

ul {
  margin-bottom: 2rem;
}

ul li {
  margin-bottom: 0.5rem;
}

p a {
  text-decoration: underline;
  background: #eee;
}

p a:hover {
  text-decoration: underline;
  background: #ddd;
}

pre code {
  white-space: pre-wrap;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  line-height: 1.25;
  margin-top: 1.5rem;
}

pre, blockquote {
  background: #f8f9fa;
  padding: 20px;
  white-space: initial;
  max-width: 100%;
  margin-bottom: 1.5rem;
  line-height: 1.75;
  border: 1px solid #dee2e6;
}

blockquote p {
  margin-bottom: 0;
}

pre .hljs {
  background: initial;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

code {
  background: #fdf3d7;
  color: #212529;
  font-size: 87.5%;
}

img {
  max-width: 100%;
}
