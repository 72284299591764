.App *:focus {
  outline: none;
}

.App .navbar-brand {
  font-weight: bold;
  letter-spacing: 0.1em;
}

.App .navbar .navbar-brand a {
  color: #333333;
}

.App .navbar .navbar-brand a:hover {
  color: #333333;
}

.App .navbar-brand small {
  font-size: 12px;
  color: #aaaaaa;
  display: block;
  margin-top: -2.5px;
}

.App .navbar a:hover, .App .navbar .navbar-nav a:hover {
  color: #17a2b8;
}

.App .navbar .navbar-nav a {
  color: #333333;
}

.App .navbar .navbar-nav a.active {
  font-weight: bold;
}

.App .contents {
  min-height: calc(100vh - 356px);
}

.App footer {
  margin-top: 50px;
  padding: 20px 0 40px 0;
  color: #ffffff;
}

.App footer h1 {
  margin-top: 5px;
  margin-bottom: -5px;
}

.App footer small {
  color: #aaaaaa;
}

.App footer h6 {
  color: #aaaaaa;
}

.App footer a {
  color: #eeeeee;
}

.App footer a:hover {
  color: #ffffff;
}

.App footer .external-icon {
  color: #666666;
  font-size: 0.7em;
  padding-bottom: 2px;
  margin-left: 2px;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
  .App footer .external-icon {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .App .navbar .navbar-nav a.active {
    border-bottom: 5px solid #17a2b8;
    margin-bottom: -8px;
  }
}