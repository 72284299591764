.Content .welcome {
  margin: 1.5rem 0 0.75rem 0;
}

.Content .welcome h2 {
  margin-top: 0;
  margin-bottom: -3px;
}

.Content .postCard {
  margin-bottom: 15px;
}

.Content .postCard h5 {
  line-height: 1.5;
}

.Content .postCard h5 a {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 2px;
  transition: none;
}

.Content .postCard h5 a:hover {
  border-bottom-color: rgb(51, 51, 51);
}

.Content .post-content a {
  text-decoration: underline;
  background: #eee;
}

.Content pre.lowlight {
  padding: 0;
  margin: 0;
  background: initial;
  border: none;
  font-size: inherit;
}
